@media (max-width: 480px)  {
    .header{
        position: fixed;
        width: 100%;
        height: 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        top: 0;
        left: 0;
        background-color: white;
        z-index: 10;
        transition: top 0.5s ease;
        padding: 1rem 2rem;
    }
    
    .header.invisible{
        top: -5rem
    }

    .header.flat.invisible{
        top: 0;
    }

    .header > .logo_link{
        height: 80%;
        width: auto;
        justify-self: flex-start;
        margin-right: auto;
    }

    .header > .logo_link > img{
        height: 100%;
        width: auto;
    }

    .header > .header_link{
        display: none;
    }

    .header > .nav_menu_button{
        width: auto;
        height: 80%;
        background: none;
        cursor: pointer;
        outline: none;
        border: none;
    }
}

@media (min-width: 481px)  {
    .header{
        width: 100%;
        height: 5rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        z-index: 10;
        box-shadow: 0 0.3rem 1rem #266DA811;
        transition: box-shadow 0.3s ease, top 0.5s ease;
    }
    
    .header.invisible{
        top: -5rem
    }

    .header.flat{
        box-shadow: 0 0 0 #266DA811;
        top: 0;

    }

    .header > .nav_menu_button{
        display: none;
    }

    .header > .logo_link{
        height: 60%;
        width: auto;
        justify-self: flex-start;
        margin-right: auto;
        margin-left: 3rem;
    }

    .header > .logo_link > img{
        height: 100%;
        width: auto;
        transition: all 0.3s ease;
    }


    .header > .header_link{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #8E8E8E;
        font-size: 0.8rem;
        margin-right: 3rem;
        text-align: center;
        text-decoration: none;
        transition: color 0.3s ease;
    }

    .header > .header_link:hover{
        color: #266DA8;
    }

    .header > .header_link_active{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 0.8rem;
        margin-right: 3rem;
        text-align: center;
        text-decoration: none;
    }
}