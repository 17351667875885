@media (max-width: 480px)  {
    .footer{
        width: 100%;
        height: auto;
        background-color: #F8F8F8;
        padding: 3rem 2rem 2rem;
    }

    .footer_desktop{
        display: none;
    }

    .footer_mobile{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .footer_section_1{
        width: 100%;
        display: flex;
        max-height: 10rem;
        flex-direction: row;
        justify-content: center;    
        margin-bottom: 2rem;    
    }

    .footer_section_1 > img.logo{
        width: 80%;
        max-width: 20rem;
        height: auto;
        object-fit: contain;
        object-position: center;
    }

    .footer_section_2{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer_section_2 > .row{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .footer_section_2 > .row > a{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #4682B4;
        font-size: 0.8rem;
        text-align: start;
        text-decoration: none;
        margin-bottom: 1rem;
    }

    .footer_section_2 > .row > a:last-child{
        margin: 0;
    }

    .footer_section_2 > .row > a > img{
        width: auto;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    .footer_section_2 > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #4682B4;
        font-size: 0.8rem;
        text-align: center;
    }
}

@media (min-width: 481px)  {
    .footer{
        width: 100%;
        height: auto;
        background-color: #F8F8F8;
        padding: 3rem 5rem 2rem;
    }

    .footer_mobile{
        display: none;
    }

    .footer_desktop{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .footer_section_1{
        width: 100%;
        height: 5rem;
        max-height: 5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }

    .footer_section_1 > img{
        height: 80%;
        max-height: 80%;
        width: auto;   
        margin-right: auto;
        margin-left: 1rem;     
    }

    .footer_section_1 > a{
        margin-right: 2rem;
        height: 1rem;
        width: auto;
    }

    .footer_section_1 > a > img{
        width: auto;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    .footer_section_2{
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        align-items: center;
    }

    .footer_section_2 > .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        max-width: 40rem;
        margin-bottom: 2rem;
    }

    .footer_section_2 > .row > a{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #4682B4;
        font-size: 0.8rem;
        text-align: center;
        text-decoration: none;
        position: relative;
        padding: 0 0.2rem;
    }

    .footer_section_2 > .row > a::after{
        content: '';
        position: absolute;
        bottom: -0.2rem;
        left: 0;
        border-top: 1px solid #4682B4;
        transform: scaleX(0);
        transform-origin: 100% center;
        transition: transform 0.4s ease;
        width: 100%;
    }

    .footer_section_2 > .row > a:hover::after{
        transform: scaleX(1);
        transform-origin: 0 center;
    }

    .footer_section_3{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .footer_section_3 > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #4682B4;
        font-size: 0.8rem;
        text-align: center;
    }
}

@media (min-width: 481px) and (max-width: 768px)  {

    .footer{
        width: 100%;
        height: auto;
        background-color: #F8F8F8;
        padding: 3rem 3rem 2rem;
    }


    .footer_section_1 > img{
        height: 60%;
        width: auto;   
        margin-right: auto;
        margin-left: 1rem;     
    }
}