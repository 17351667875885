@media (max-width: 480px)  {
    .contact{
        width: 100%;
        height: auto;
    }

    .contact_section_1{
        width: 100%;
        height: auto;
        padding: 10rem 3rem 3rem;        
    }

    .contact_section_1 > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266da8;
        font-size: 2rem;
        text-align: start;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 0.7rem;
        line-height: 0.8;
    }

    .contact_section_1 > h3{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 1rem;
        text-align: start;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0;
    }

    .contact_section_2{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .contact_section_2 > .info{
        width: 100%;
        height: auto;
        padding: 0 3rem 2rem;
        margin-bottom: 2rem;
    }

    .contact_section_2 > .info > .info_item{
        width: 100%;
        height: auto;
    }

    .contact_section_2 > .info > .info_item > h4{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266da8;
        font-size: 2rem;
        text-align: start;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 0.4rem;
    }

    .contact_section_2 > .info > .info_item > h5{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 1.5rem;
        text-align: start;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 0.4rem;
    }

    .contact_section_2 > .info > .info_item > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: black;
        font-size: 0.8rem;
        text-align: start;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 0.4rem;
    }

    .contact_section_2 > .form{
        width: 100%;
        height: auto;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        background-color: #266da8;
        align-items: center;
    }

    .contact_section_2 > .form  .MuiInputBase-root{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 0.85rem;
        text-align: start;
        width: 100%;
    }

    .contact_section_2 > .form > .submit{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .contact_section_2 > .form > .submit > button{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266da8;
        font-size: 0.85rem;
        text-align: center;
        letter-spacing: 0.02rem;
        background-color: white;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 2rem;
        padding: 0.8rem 2rem;
        margin-bottom: 1rem;
        width: 12rem;
    }

    .contact_section_2 > .form > .submit > button.loading{
        cursor: default;
        background-color: transparent;
    }

    .contact_section_2 > .form > .submit > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #FFFFFF80;
        font-size: 0.85rem;
        text-align: start;
        letter-spacing: 0.02rem;
    }

    .contact_section_2 > .form > .submit > p > a{
        color: white;
        cursor: pointer;
        text-decoration: none;
        position: relative;
    }

    .contact_section_2 > .form > .submit > p > a::after{
        border-top: 2px solid white;
        width: 100%;
        height: 0;
        position: absolute;
        bottom: -0.2rem;
        left: 0; 
        content: "";
        transition: transform 0.2s ease;
        transform: scaleX(0); 
        transform-origin: center;
    }

    .contact_section_2 > .form > .submit > p > a:hover::after{
        transform: scaleX(1);
    }
}

@media (min-width: 481px)  {
    .contact{
        width: 100%;
        height: auto;
    }

    .contact_section_1{
        width: 100%;
        height: auto;
        padding: 10rem 5rem 5rem;        
    }

    .contact_section_1 > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266da8;
        font-size: 3rem;
        text-align: start;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 0.7rem;
        line-height: 0.8;
    }

    .contact_section_1 > h3{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 1.4rem;
        text-align: start;
        letter-spacing: 0.08rem;
        width: 100%;
        margin: 0;
    }

    .contact_section_2{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
    }

    .contact_section_2 > .info{
        width: 30%;
        height: auto;
        padding: 0 5rem;
    }

    .contact_section_2 > .info > .info_item{
        width: 100%;
        height: 100%;
    }

    .contact_section_2 > .info > .info_item > h4{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266da8;
        font-size: 1.4rem;
        text-align: start;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 0.6rem;
    }

    .contact_section_2 > .info > .info_item > h5{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 1.2rem;
        text-align: start;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 0.6rem;
    }

    .contact_section_2 > .info > .info_item > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: black;
        font-size: 0.75rem;
        text-align: start;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 0.6rem;
    }

    .contact_section_2 > .form{
        width: 70%;
        height: auto;
        padding: 5rem;
        display: flex;
        flex-direction: column;
        background-color: #266da8;
        align-items: center;
    }

    .contact_section_2 > .form  .MuiInputBase-root{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 0.85rem;
        text-align: start;
        width: 100%;
        max-width: "30rem",
    }

    .contact_section_2 > .form > .submit{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: auto;
        max-width: 30rem;
    }

    .contact_section_2 > .form > .submit > button{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266da8;
        font-size: 0.85rem;
        text-align: center;
        letter-spacing: 0.02rem;
        background-color: white;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 2rem;
        padding: 0.8rem 2rem;
        margin-right: 2rem;
        width: 16rem;
    }

    .contact_section_2 > .form > .submit > button.loading{
        cursor: default;
        background-color: transparent;
    }

    .contact_section_2 > .form > .submit > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #FFFFFF80;
        font-size: 0.85rem;
        text-align: start;
        letter-spacing: 0.02rem;
    }

    .contact_section_2 > .form > .submit > p > a{
        color: white;
        cursor: pointer;
        text-decoration: none;
        position: relative;
    }

    .contact_section_2 > .form > .submit > p > a::after{
        border-top: 2px solid white;
        width: 100%;
        height: 0;
        position: absolute;
        bottom: -0.2rem;
        left: 0; 
        content: "";
        transition: transform 0.2s ease;
        transform: scaleX(0); 
        transform-origin: center;
    }

    .contact_section_2 > .form > .submit > p > a:hover::after{
        transform: scaleX(1);
    }
}

@media (min-width: 481px) and (max-width: 786px) {
    .contact_section_1{
        padding: 10rem 2rem 5rem;        
    }

    .contact_section_2 > .info{
        width: 40%;
        padding: 0 2rem;
    }

    .contact_section_2 > .form{
        width: 60%;
        padding: 2rem;
    }

    .contact_section_2 > .form > .submit{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .contact_section_2 > .form > .submit > button{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266da8;
        font-size: 0.85rem;
        text-align: center;
        letter-spacing: 0.02rem;
        background-color: white;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 2rem;
        padding: 0.8rem 2rem;
        margin-bottom: 1rem;
        width: 12rem;
        align-self: flex-start;
    }

    .contact_section_2 > .form > .submit > button.loading{
        cursor: default;
        background-color: transparent;
    }

    .contact_section_2 > .form > .submit > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #FFFFFF80;
        font-size: 0.85rem;
        text-align: start;
        letter-spacing: 0.02rem;
    }
}