@media (max-width: 480px)  {
    .privacy_and_terms{
        width: 100%;
        height: auto;
        min-height: 50rem;
        padding: 8rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .privacy_and_terms > h1{
        width: 100%;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 2rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 3rem;
        max-width: 50rem;
    }

    .privacy_and_terms > h3{
        width: 100%;
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: black;
        font-size: 1.2rem;
        text-align: start;
        letter-spacing: 0.04rem;
        margin: 0 0 1rem;
        line-height: 1.5;
        max-width: 50rem;
    }

    .privacy_and_terms > p{
        width: 100%;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 0.75rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 2rem;
        line-height: 1.5;
        max-width: 50rem;
    }

    .privacy_and_terms > ul,
    .privacy_and_terms > ol{
        width: 100%;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 0.75rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 2rem;
        line-height: 1.5;
        max-width: 50rem;
    }

    .privacy_and_terms  li{
        white-space: normal;
        word-wrap: break-word;
    }
}

@media (min-width: 481px)  {
    .privacy_and_terms{
        width: 100%;
        height: auto;
        min-height: 50rem;
        padding: 8rem 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .privacy_and_terms > h1{
        width: 100%;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 2rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 3rem;
        max-width: 50rem;
    }

    .privacy_and_terms > h3{
        width: 100%;
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: black;
        font-size: 1.2rem;
        text-align: start;
        letter-spacing: 0.04rem;
        margin: 0 0 1rem;
        line-height: 1.5;
        max-width: 50rem;
    }

    .privacy_and_terms > p{
        width: 100%;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 0.75rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 2rem;
        line-height: 1.5;
        max-width: 50rem;
    }

    .privacy_and_terms > ul,
    .privacy_and_terms > ol{
        width: 100%;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 0.75rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 2rem;
        line-height: 1.5;
        max-width: 50rem;
    }

    .privacy_and_terms  li{
        white-space: normal;
        word-wrap: break-word;
    }
}