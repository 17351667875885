@media (max-width: 480px)  {
    .stat_item{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 3rem;        
    }

    .stat_item > p.number{
        font-family: 'OpenSans-Semibold';
        font-weight: normal;
        color: white;
        font-size: 3rem;
        text-align: center;
        letter-spacing: 0.02rem;
        margin: 0 0 0.5rem;
        width: 100%;
    }

    .stat_item > p.description{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 0.5rem;
    }
}

@media (min-width: 481px)  {
    .stat_item{
        display: flex;
        flex-direction: column;
        width: 20%;
        max-width: 20rem;        
    }

    .stat_item > p.number{
        font-family: 'OpenSans-Semibold';
        font-weight: normal;
        color: white;
        font-size: 3rem;
        text-align: center;
        letter-spacing: 0.02rem;
        margin: 0 0 0.5rem;
        width: 100%;
    }

    .stat_item > p.description{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 0.5rem;
    }
}