.partner{
    border: 1px solid #FFFFFF66;
    padding: 1rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 10rem;
    min-height: 10rem;
    max-width: 30rem;
    max-height: 30rem;
}

.partner > img{
    width: 60%;
    max-height: 80%;
    object-fit: contain;
    object-position: center;
}

.partner > p{
    font-family: 'OpenSans-Regular';
    font-weight: normal;
    color: white;
    font-size: 0.75rem;
    text-align: start;
    letter-spacing: 0.02rem;
    margin: 0 0 1rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
}