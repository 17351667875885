@media (max-width: 480px)  {
    .home{
        width: 100%;
        height: auto;
    }

    .home_top{
        width: 100%;
        height: auto;     
        max-height: 50rem;   
        padding: 10rem 3rem 8rem;  
        box-sizing: border-box;      
    }

    .home_top > h1{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: #266DA8;
        margin: 1rem auto;
        font-size: 1.8rem;
        text-align: center;
    }

    .home_top > img{        
        margin: 1rem 0;
        object-fit: contain;
        object-position: center;
        height: auto;
        width: 100%;        
    }


    .home_section_1{
        width: 100%;
        height: auto;
        background-color: #266DA8;
        display: flex;
        flex-direction: column;
        padding: 5rem 2rem;
        align-items: center;
        margin-bottom: 5rem;
    }

    .home_section_1 > p.medium_text{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        margin: 0 auto 1.5rem;
        font-size: 1rem;
        text-align: center;
    }

    .home_section_1 > p.large_text{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        margin: 0 auto 1.5rem;
        font-size: 1.6rem;
        text-align: center;
        letter-spacing: 0.02rem;
    }

    .home_section_1 > p.small_text{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: white;
        margin: 0 auto 1.5rem;
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0.02rem;
    }

    .home_section_1 > a{
        background: none;
        outline: none;
        border: none;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 1.2rem;
        text-align: center;
        letter-spacing: 0.02rem;
        cursor: pointer;
        transition: transform 0.1s ease-in-out;
        text-decoration: none;
    }

    .home_section_1 > a:hover{
        transform: scale(1.05);
    }

    .home_section_1 > a > img{
        height: 1rem;
        width: auto;
        margin: 0.1rem 0 0 0.5rem;
    }

    .home_info_item_1, .home_info_item_2{
        width: 100%;
        height: auto;
        padding: 0 1.5rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 8rem;
    }    

    .home_info_item_1 > .textbox, .home_info_item_2 > .textbox{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        justify-content: center;       
    }

    .home_info_item_1 > .textbox > h2, .home_info_item_2 > .textbox > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 1.2rem;
        text-align: start;
        margin: 0 0 1rem;
    }

    .home_info_item_1 > .textbox > p.title, .home_info_item_2 > .textbox > p.title{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #1A1A1A;
        font-size: 1.6rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
    }

    .home_info_item_1 > .textbox > p, .home_info_item_2 > .textbox > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: #0D0D0D;
        font-size: 1rem;
        text-align: start;
        letter-spacing: 0.02rem;
        line-height: 1.5;
        margin: 0 0 1rem;
    }

    .home_info_item_1 > .textbox > a, .home_info_item_2 > .textbox > a{
        background: none;
        outline: none;
        border: none;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 1.2rem;
        text-align: start;
        letter-spacing: 0.02rem;
        cursor: pointer;
        transition: transform 0.1s ease;
        display: flex;
        text-decoration: none;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .home_info_item_1 > .textbox > a:hover, .home_info_item_2 > .textbox > a:hover{
        transform: scale(1.1);
    }

    .home_info_item_1 > .textbox > a > svg, .home_info_item_2 > .textbox > a > svg{
        height: 0.8rem;
        width: auto;
        margin: 0.1rem 0 0 0.5rem;
    }

    .home_info_item_1 > .textbox > a > svg *, .home_info_item_2 > .textbox > a > svg *{
        fill: #266DA8;
    }

    .home_info_item_1 > .imagebox, .home_info_item_2 > .imagebox{
        width: 100%;
        margin-bottom: 2rem;
    }

    .home_info_item_1 > .imagebox > img, .home_info_item_2 > .imagebox > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        opacity: 0;
        transition: opacity 0.2s ease;
    }


    .home_section_2{
        width: 100%;
        height: auto;
        background-color: #266DA8;
        padding: 5rem 1.5rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 5rem;
    }

    .home_section_2 > .textbox{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        justify-content: center;
    }

    .home_section_2 > .textbox > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 8vw;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 2rem;
        max-width: 80vw;
    }

    .home_section_2 > .textbox > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: white;
        font-size: 1rem;
        text-align: start;
        letter-spacing: 0.02rem;
        line-height: 1.8;
        margin: 0 0 3rem;
    }

    .home_section_2 > .textbox > a{
        background: none;
        outline: none;
        border: none;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 1.2rem;
        text-align: start;
        letter-spacing: 0.02rem;
        cursor: pointer;
        transition: transform 0.1s ease-in-out;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
    }

    .home_section_2 > .textbox > a:hover{
        transform: scale(1.1);
    }

    .home_section_2 > .textbox > a > img{
        height: 0.8rem;
        width: auto;
        margin: 0.1rem 0 0 0.5rem;
    }

    .home_section_2 > .imagebox{
        width: 100%;
        margin-bottom: 2rem;
    }

    .home_section_2 > .imagebox > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
    }
}

@media (min-width: 481px)  {
    .home{
        width: 100%;
        height: auto;
    }

    .home_top{
        width: 100%;
        height: auto;     
        min-height: 100vh;
        max-height: 50rem;   
        padding: 7rem 3rem 8rem;  
        box-sizing: border-box;      
    }

    .home_top > h1{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: #266DA8;
        margin: 1rem auto;
        font-size: 1.65rem;
        text-align: center;
        max-width: 35rem;
    }

    .home_top > img{        
        margin: 1rem 0;
        object-fit: contain;
        object-position: center;
        height: auto;
        width: 100%;
        max-width: 38rem;
    }


    .home_section_1{
        width: 100%;
        height: auto;
        background-color: #266DA8;
        display: flex;
        flex-direction: column;
        padding: 5rem;
        align-items: center;
        margin-bottom: 8rem;
    }

    .home_section_1 > p.medium_text{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        margin: 0 auto 1rem;
        font-size: 0.9rem;
        letter-spacing: 0.02rem;
        text-align: center;
    }

    .home_section_1 > p.large_text{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        margin: 0 auto 1rem;
        font-size: 1.5rem;
        text-align: center;
        letter-spacing: 0.02rem;
    }

    .home_section_1 > p.small_text{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: white;
        margin: 0 auto 1.5rem;
        font-size: 0.85rem;
        text-align: center;
        max-width: 30rem;
        letter-spacing: 0.02rem;
    }

    .home_section_1 > a{
        background: none;
        outline: none;
        border: none;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 0.9rem;
        text-align: center;
        letter-spacing: 0.02rem;
        cursor: pointer;
        transition: transform 0.1s ease-in-out;
        text-decoration: none;
    }

    .home_section_1 > a:hover{
        transform: scale(1.05);
    }

    .home_section_1 > a > img{
        height: 0.8rem;
        width: auto;
        margin: 0.1rem 0 0 0.5rem;
    }

    .home_info_item_1{
        width: 100%;
        height: auto;
        padding: 0 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8rem;
    }    

    .home_info_item_2{
        width: 100%;
        height: auto;
        padding: 0 3rem;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-bottom: 8rem;
    }

    .home_info_item_1 > .textbox, .home_info_item_2 > .textbox{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        justify-content: center;       
    }

    .home_info_item_1 > .textbox{
        padding: 0 3rem 0 0;
    }

    .home_info_item_2> .textbox{
        padding: 0 0 0 3rem;
    }

    .home_info_item_1 > .textbox > h2, .home_info_item_2 > .textbox > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 0.8rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
    }

    .home_info_item_1 > .textbox > p.title, .home_info_item_2 > .textbox > p.title{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #1A1A1A;
        font-size: 1.5rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
    }

    .home_info_item_1 > .textbox > p, .home_info_item_2 > .textbox > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: #0D0D0D;
        font-size: 0.75rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
        max-width: 25rem;
    }

    .home_info_item_1 > .textbox > a, .home_info_item_2 > .textbox > a{
        background: none;
        outline: none;
        border: none;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 0.9rem;
        text-align: start;
        letter-spacing: 0.02rem;
        cursor: pointer;
        transition: transform 0.1s ease;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
    }

    .home_info_item_1 > .textbox > a:hover, .home_info_item_2 > .textbox > a:hover{
        transform: scale(1.1);
    }

    .home_info_item_1 > .textbox > a > svg, .home_info_item_2 > .textbox > a > svg{
        height: 0.8rem;
        width: auto;
        margin: 0.1rem 0 0 0.5rem;
    }

    .home_info_item_1 > .textbox > a > svg *, .home_info_item_2 > .textbox > a > svg *{
        fill: #266DA8;
    }

    .home_info_item_1 > .imagebox, .home_info_item_2 > .imagebox{
        width: 50%;
        height: 100%;
    }

    .home_info_item_1 > .imagebox > img, .home_info_item_2 > .imagebox > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        opacity: 0;
        transition: opacity 0.2s ease;
    }


    .home_section_2{
        width: 100%;
        height: auto;
        background-color: #266DA8;
        padding: 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5rem;
    }

    .home_section_2 > .textbox{
        width: 50%;
        height: 100%;
        padding: 0 3rem 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        justify-content: center;
    }

    .home_section_2 > .textbox > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 1.5rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 2rem;
    }

    .home_section_2 > .textbox > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: white;
        font-size: 0.75rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 3rem;
        max-width: 25rem;
    }

    .home_section_2 > .textbox > a{
        background: none;
        outline: none;
        border: none;
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 1rem;
        text-align: start;
        letter-spacing: 0.02rem;
        cursor: pointer;
        transition: transform 0.1s ease-in-out;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
    }

    .home_section_2 > .textbox > a:hover{
        transform: scale(1.1);
    }

    .home_section_2 > .textbox > a > img{
        height: 0.8rem;
        width: auto;
        margin: 0.1rem 0 0 0.5rem;
    }

    .home_section_2 > .imagebox{
        width: 50%;
        height: 100%;
    }

    .home_section_2 > .imagebox > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
    }
}

@media (min-width: 481px) and (max-width: 688px) {

    .home_info_item_1, .home_info_item_2{
        padding: 0 2rem;
    }

    .home_info_item_1 > .textbox, .home_info_item_2 > .textbox{
        width: 60%;
    }

    .home_info_item_1 > .textbox{
        padding-right: 2rem;
    }

    .home_info_item_2 > .textbox{
        padding-left: 2rem;
    }

    .home_info_item_1 > .textbox > p, .home_info_item_2 > .textbox > p,
    .home_info_item_1 > .textbox > p.title, .home_info_item_2 > .textbox > p.title,
    .home_info_item_1 > .textbox > h2, .home_info_item_2 > .textbox > h2{
        margin-bottom: 0.5rem;
    }

    .home_info_item_1 > .imagebox, .home_info_item_2 > .imagebox{
        width: 40%;
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .home_info_item_1 > .imagebox > img, .home_info_item_2 > .imagebox > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .home_section_2{
        padding: 5rem 2rem;
    }

    .home_section_2 > .textbox{
        padding-right: 2rem;
    }

    .home_section_2 > .textbox > h2,
    .home_section_2 > .textbox > p{
        margin-bottom: 0.5rem;
    }
}