@media (max-width: 480px)  {
    .innerpage{
        width: 100%;
        height: auto;
    }

    .innerpage_section_1{
        width: 100%;
        height: auto;
        padding: 7rem 1rem 5rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .innerpage_section_1 > .textbox{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center;
    }

    .innerpage_section_1 > .textbox > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #1A1A1A;
        font-size: 1.5rem;
        text-align: center;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
    }

    .innerpage_section_1 > .textbox > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: #0D0D0D;
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
    }

    .innerpage_section_1 > .imagebox{
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
    }

    .innerpage_section_1 > .imagebox > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .innerpage_section_2{
        width: 100%;
        height: auto;
        padding: 5rem 1.5rem 5rem;
        display: flex;
        flex-direction: column;
        background-color: #266DA8;
        margin-bottom: 10rem; 
    }

    .innerpage_section_2 > p{
        width: 100%;
        min-height: 3rem;
        color: white;
        font-family: 'OpenSans-Regular', sans-serif;
        font-size: 1rem;
        text-align: start;
        padding: 1rem 0;
        letter-spacing: 0.02;
        margin: 0 0 2rem;
    }

    .innerpage_section_2 > *:last-child{
        margin-bottom: 0;
    }

    .innerpage_section_2 > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 1.8rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 3rem;
    }

    .innerpage_section_3 {
        width: 100%;
        height: auto;
    }

    .innerpage_info_item{
        width: 100%;
        height: auto;
        padding: 0 1rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 8rem;
    }

    .innerpage_info_item > .textbox{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center;  
    }

    .innerpage_info_item > .textbox > h3{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 1.5rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem; 
        width: 100%;
    }

    .innerpage_info_item > .textbox > p.title{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #1A1A1A;
        font-size: 1.8rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
        width: 100%;
    }

    .innerpage_info_item > .textbox > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: #0D0D0D;
        font-size: 0.95rem;
        text-align: start;
        letter-spacing: 0.02rem;
    }

    .innerpage_info_item > .imagebox{
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
    }

    .innerpage_info_item > .imagebox > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .innerpage_section_4{
        width: 100%;
        height: auto;
        padding: 5rem 0 2rem;
        margin-bottom: 10rem;
        background-color: #266DA8;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .innerpage_section_6{
        width: 100%;
        height: auto;
        background-color: #266DA8;
        padding: 3rem 3rem 0;
        margin-bottom: 10rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .innerpage_section_7 > h2{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: black;
        font-size: 1.8rem;
        text-align: center;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 8rem;
    }

    .innerpage_section_7 > h2 > a{
        text-decoration: none;
        color: #266da8;
        position: relative;
    }

    .innerpage_section_7 > h2 > a::after{
        border-top: 2px solid #266da8;
        width: 100%;
        height: 0;
        position: absolute;
        bottom: -0.2rem;
        left: 0; 
        content: "";
        transition: transform 0.2s ease;
        transform: scaleX(0); 
        transform-origin: center;
    }

    .innerpage_section_7 > h2 > a:hover::after{
        transform: scaleX(1);
    }
}

@media (min-width: 481px)  {

    .innerpage{
        width: 100%;
        height: auto;
    }

    .innerpage_section_1{
        width: 100%;
        height: auto;
        padding: 7rem 3rem 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .innerpage_section_1 > .textbox{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        justify-content: center;
        padding: 0 3rem 0 0;
    }

    .innerpage_section_1 > .textbox > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #1A1A1A;
        font-size: 1.5rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
    }

    .innerpage_section_1 > .textbox > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: #0D0D0D;
        font-size: 0.75rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
        max-width: 25rem;
    }

    .innerpage_section_1 > .imagebox{
        width: 50%;
        height: auto;
        min-height: 20rem;
    }

    .innerpage_section_1 > .imagebox > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .innerpage_section_2{
        width: 100%;
        height: auto;
        padding: 5rem 5rem 5rem;
        display: flex;
        flex-direction: column;
        background-color: #266DA8;
        margin-bottom: 10rem; 
    }

    .innerpage_section_2 > p{
        width: 100%;
        height: 3rem;
        color: white;
        font-family: 'OpenSans-Regular', sans-serif;
        font-size: 1rem;
        text-align: start;
        padding: 1rem 0;
        letter-spacing: 0.02;
        margin: 0 0 2rem;
    }

    .innerpage_section_2 > *:last-child{
        margin-bottom: 0;
    }

    .innerpage_section_2 > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 1.8rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 2rem;
    }

    .innerpage_section_3 {
        width: 100%;
        height: auto;
    }

    .innerpage_info_item{
        width: 100%;
        height: auto;
        padding: 0 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10rem;
    }

    .innerpage_info_item:nth-child(even){
        flex-direction: row-reverse;
    }

    .innerpage_info_item > .textbox{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        justify-content: center;  
        padding: 0 3rem 0 0;
    }

    .innerpage_info_item:nth-child(even) > .textbox{
        padding: 0 0 0 3rem;
    }

    .innerpage_info_item > .textbox > h3{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 1rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem; 
    }

    .innerpage_info_item > .textbox > p.title{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #1A1A1A;
        font-size: 1.5rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
    }

    .innerpage_info_item > .textbox > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: #0D0D0D;
        font-size: 0.75rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
        max-width: 25rem;
    }

    .innerpage_info_item > .imagebox{
        width: 50%;
        height: 100%;
    }

    .innerpage_info_item > .imagebox > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .innerpage_section_4{
        width: 100%;
        height: auto;
        padding: 5rem 0 2rem;
        margin-bottom: 10rem;
        background-color: #266DA8;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .innerpage_section_6{
        width: 100%;
        height: auto;
        background-color: #266DA8;
        padding: 8rem 5rem;
        margin-bottom: 10rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .innerpage_section_7 > h2{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: black;
        font-size: 2rem;
        text-align: center;
        letter-spacing: 0.02rem;
        width: 100%;
        margin: 0 0 8rem;
    }

    .innerpage_section_7 > h2 > a{
        text-decoration: none;
        color: #266da8;
        position: relative;
    }

    .innerpage_section_7 > h2 > a::after{
        border-top: 2px solid #266da8;
        width: 100%;
        height: 0;
        position: absolute;
        bottom: -0.2rem;
        left: 0; 
        content: "";
        transition: transform 0.2s ease;
        transform: scaleX(0); 
        transform-origin: center;
    }

    .innerpage_section_7 > h2 > a:hover::after{
        transform: scaleX(1);
    }

}