.testimonials{
    width: 100%;
    padding: 0 5rem;
    margin-bottom: 10rem;        
}

.testimonials > h2{
    font-family: 'OpenSans-Regular';
    font-weight: normal;
    color: #266DA8;
    font-size: 2rem;
    text-align: start;
    letter-spacing: 0.02rem;
    margin: 0 0 2rem; 
}
.testimonials > .buttons{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 1.2rem;
    align-items: center;
    margin-bottom: 2rem;
}

.testimonials > .buttons > .arrow-left, .testimonials > .buttons > .arrow-right{
    height: 100%;
    width: auto;
    margin-right: 1rem;
    background: none;
    outline: none;
    border: none;
    cursor: pointer  
}

.testimonials > .buttons svg{
    height: 100%;
    width: auto;
    stroke: black;
    fill: black;
    transition: stroke 0.2s ease, fill 0.2s ease;
}

.testimonials > .buttons > :disabled > svg{
    stroke: #B0B0B0;
    fill: #B0B0B0;
    cursor: default;
}

.testimonials > .buttons > .arrow-left > svg{
    transform: rotate(180deg);
    transform-origin: center;
}

.testimonials > .buttons > .divider_line{
    width: auto;
    flex-grow: 1;
    height: 0;
    border-top: 1px solid #266DA8;
}

.testimonials > .testimonial_item{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}

.testimonials > .testimonial_item > .main{
    width: 75%;
    height: auto;
    min-height: 10rem;
    padding: 0 2rem 0 0;
    position: relative;
    overflow-x: hidden;
}

.testimonials > .testimonial_item > .main > p{
    font-family: 'OpenSans-Light';
    font-weight: normal;
    color: black;
    font-size: 0.85rem;
    text-align: start;
    letter-spacing: 0.02rem;
    max-width: 35rem;
    position: absolute;
    max-height: 100%;
    top: 50%;
    margin: 0;
}

.testimonials > .testimonial_item > .extra{
    width: 25%;
    height: auto;
    position: relative;
}

.testimonials > .testimonial_item > .extra > .extra_content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    padding-left: 1rem;    
}

.testimonials > .testimonial_item > .extra > .extra_content > p.name{
    font-family: 'OpenSans-Regular';
    font-weight: normal;
    color: #0D0D0D;
    font-size: 1rem;
    text-align: start;
    letter-spacing: 0.02rem;
    margin: 0 0 0.3rem;
}

.testimonials > .testimonial_item > .extra > .extra_content > p.position{
    font-family: 'OpenSans-Light';
    font-weight: normal;
    color: black;
    font-size: 0.8rem;
    text-align: start;
    letter-spacing: 0.02rem;
    margin: 0 0 0.3rem;
}

.testimonials > .testimonial_item > .extra > .extra_content > p.org{
    font-family: 'OpenSans-Light';
    font-weight: normal;
    color: black;
    font-size: 0.75rem;
    text-align: start;
    letter-spacing: 0.02rem;
    margin: 0;
}

@media (min-width: 481px) and (max-width: 766px){
    .testimonials > .testimonial_item > .main{
        width: 85%;
    }

    .testimonials > .testimonial_item > .extra{
        width: 15%;
    }
}

@media (max-width: 480px){
    .testimonials{
        padding: 0 2rem;
    }

    .testimonials > .testimonial_item{
        flex-direction: column;
    }

    .testimonials > .testimonial_item > .main{
        width: 100%;
        min-height: 15rem;
        margin-bottom: 1rem;
    }

    .testimonials > .testimonial_item > .main > p{
        font-size: 1rem;
    }

    .testimonials > .testimonial_item > .extra{
        align-self: flex-end;
        width: 10rem;
    }

    .testimonials > .testimonial_item > .extra > .extra_content > p.name{
        font-size: 1.2rem;
    }
    
    .testimonials > .testimonial_item > .extra > .extra_content > p.position{
        font-size: 1rem;
    }
    
    .testimonials > .testimonial_item > .extra > .extra_content > p.org{
        font-size: 0.85rem;
    }
}