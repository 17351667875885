body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body *{
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

@media (max-width: 480px){
    html {
      font-size: 14px;
    }
  }

@media (min-width: 481px) and (max-width: 768px){
    html {
      font-size: 16px;
    }
  }
  
@media (min-width: 769px) and (max-width: 1024px)  {
    html {
        font-size: 18px;
    }
}

@media (min-width: 1025px) and (max-width: 1200px)  {
    html {
        font-size: 20px;
    }
}

@media (min-width: 1201px){
    html {
        font-size: 22px;
    }
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('./Fonts/OpenSans-Regular.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'OpenSans-Semibold';
    src: url('./Fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url('./Fonts/OpenSans-Light.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('./Fonts/OpenSans-Bold.ttf') format('truetype');
    font-display: block;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
