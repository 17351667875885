@media (max-width: 480px)  {
    .about{
        width: 100%;
        height: auto;
    }

    .about_section_1{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 5rem;
    }

    .about_section_1 > h3{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #1A1A1A;
        font-size: 2rem;
        text-align: center;
        margin: 4rem auto;
    }

    .about_section_1 > img{
        width: 100%;
        height: auto;
        max-height: 30rem;
        min-height: 20rem;
        object-fit: cover;
        object-position: center;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .about_section_2{
        width: 100%;
        height: auto;
        padding: 0 1rem;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 5rem;
    }

    .about_section_2 > .textbox{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center; 
    }

    .about_section_2 > .textbox > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #1A1A1A;
        font-size: 1.8rem;
        text-align: center;
        letter-spacing: 0.02rem;
        margin: 0 0 2rem;
    }

    .about_section_2 > .textbox > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: #0D0D0D;
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
        max-width: 25rem;
    }

    .about_section_2 > .imagebox{
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;
    }

    .about_section_2 > .imagebox > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .about_section_3{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 0 0 5rem;
        padding: 4rem 1rem;
        background-color: #266DA8;
    }

    .about_section_3 > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 2rem;
        text-align: center;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
    }

    .about_section_3 > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: white;
        font-size: 1rem;
        text-align: center;
        margin: 0 0 4rem;
        max-width: 30rem;
    }

    .about_section_3 > p:last-child{
        margin-bottom: 0;
    }

    .about_section_4{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5rem 2rem;
        margin-bottom: 5rem;
        background-color: #266DA8;
    }

    .about_section_4 > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 2rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 2rem;
    }

    .about_section_4 > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: white;
        font-size: 1rem;
        text-align: start;
        margin-bottom: 1rem;
        max-width: 40rem;
    }

    .about_section_4 > p:last-child{
        margin-bottom: 0;
    }

    .about_section_5{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin-bottom: 10rem;
    }

    .about_section_5 > a{
        width: 100%;
        height: 20rem;
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }   

    .about_section_5 > a > img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        filter: brightness(50%);
        opacity: 0;
        transition: filter 0.2s ease-in-out, opacity 0.3s ease;
    }

    .about_section_5 > a:hover > img{
        filter: brightness(20%);
    }

    .about_section_5 > a > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 2rem;
        text-align: center;
        z-index: 5;
        text-decoration: none;        
    }

    .about_section_6{
        width: 80%;
        margin: 0 auto 8rem;
        display: flex;
        flex-direction: column;
    }

    .about_section_6 > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 2.3rem;
        text-align: center;
        margin: 0 0 1.5rem;
    }

    .about_section_6 > .main{
        height: 35rem;
        width: 100%;
        margin-bottom: 3rem;
        position: relative;
        overflow-x: hidden;
    }

    .about_section_6 > .main > .item{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .about_section_6 > .main > .item > img{
        width: 100%;
        height: 40%;
        object-fit: contain;
        object-position: center;
        margin-bottom: 2rem;
    }

    .about_section_6 > .main > .item > .content{
        width: 100%;
        height: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow-y: auto;
    }

    .about_section_6 > .main > .item > .content > p.name{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 1.5rem;
        text-align: start;
        margin: 0 0 0.5rem;
    }

    .about_section_6 > .main > .item > .content > p.title{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 0.9rem;
        text-align: start;
        margin: 0 0 1.5rem;
    }

    .about_section_6 > .main > .item > .content > .descriptions{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .about_section_6 > .main > .item > .content > .descriptions > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 0.75rem;
        text-align: start;
        margin: 0 0 0.6rem;
    }

    .about_section_6 > .controls{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .about_section_6 > .controls > .line{
        width: auto;
        height: 0;
        border-top: 1px solid black;
        flex: 1;
    }

    .about_section_6 > .controls > button{
        width: 2rem;
        height: 2rem;
        background-color: white;
        border: none;
        outline: none;
        margin-left: 1rem;
        cursor: pointer;
    }

    .about_section_6 > .controls > button:disabled{
        cursor: default;
    }
    
    .about_section_6 > .controls > button > svg{
        width: 100%;
        height: 100%;
        transition: opacity 0.2s ease;
    }

    .about_section_6 > .controls > button:nth-child(2) > svg{
        transform: rotate(180deg);
    }

    .about_section_6 > .controls > button:disabled > svg{
        opacity: 0.3;
    }
}

@media (min-width: 481px)  {
    .about{
        width: 100%;
        height: auto;
    }

    .about_section_1{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 5rem;
    }

    .about_section_1 > h3{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #1A1A1A;
        font-size: 2.5rem;
        text-align: center;
        margin: 6rem auto;
    }

    .about_section_1 > img{
        width: 100%;
        height: auto;
        max-height: 25rem;
        object-fit: cover;
        object-position: center;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .about_section_2{
        width: 100%;
        height: auto;
        padding: 0 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8rem;
    }

    .about_section_2 > .textbox{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        justify-content: center;
        padding: 0 3rem 0;
    }

    .about_section_2 > .textbox > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #1A1A1A;
        font-size: 1.5rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 3rem;
    }

    .about_section_2 > .textbox > p{
        font-family: 'OpenSans-Light';
        font-weight: normal;
        color: #0D0D0D;
        font-size: 0.75rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 1rem;
        max-width: 25rem;
    }

    .about_section_2 > .imagebox{
        width: 50%;
        height: 100%;
    }

    .about_section_2 > .imagebox > img{
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .about_section_3{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 0 0 10rem;
        padding: 5rem 0 5rem;
        background-color: #266DA8;
    }

    .about_section_3 > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 2rem;
        text-align: center;
        letter-spacing: 0.02rem;
        margin: 0 0 2rem;
    }

    .about_section_3 > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 0.95rem;
        text-align: center;
        max-width: 30rem;
        margin: 0 0 5rem;
    }

    .about_section_3 > p:last-child{
        margin-bottom: 0;
    }

    .about_section_4{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5rem 5rem;
        margin-bottom: 5rem;
        background-color: #266DA8;
    }

    .about_section_4 > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 1.8rem;
        text-align: start;
        letter-spacing: 0.02rem;
        margin: 0 0 3.5rem;
    }

    .about_section_4 > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 0.85rem;
        text-align: start;
        margin-bottom: 1rem;
        max-width: 50rem;
    }

    .about_section_4 > p:last-child{
        margin-bottom: 0;
    }

    .about_section_5{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        margin-bottom: 8rem;
    }

    .about_section_5 > a{
        width: 50%;
        height: 40vw;
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }   

    .about_section_5 > a > img{
        position: absolute;
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
        object-position: center;
        filter: brightness(50%);
        opacity: 0;
        transition: filter 0.2s ease-in-out, opacity 0.3s ease;
    }

    .about_section_5 > a:hover > img{
        filter: brightness(20%);
    }

    .about_section_5 > a > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 2rem;
        text-align: center;
        z-index: 5;
        text-decoration: none;        
    }

    .about_section_6{
        width: 80%;
        margin: 0 auto 8rem;
        display: flex;
        flex-direction: column;
    }

    .about_section_6 > h2{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 2.3rem;
        text-align: center;
        margin: 0 0 1.5rem;
    }

    .about_section_6 > .main{
        height: 25rem;
        width: 100%;
        margin-bottom: 3rem;
        position: relative;
        overflow-x: hidden;
    }

    .about_section_6 > .main > .item{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .about_section_6 > .main > .item > img{
        width: 45%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    .about_section_6 > .main > .item > .content{
        width: 45%;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow-y: auto;
    }

    .about_section_6 > .main > .item > .content > p.name{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: #266DA8;
        font-size: 1.2rem;
        text-align: start;
        margin: 0 0 0.4rem;
    }

    .about_section_6 > .main > .item > .content > p.title{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 0.9rem;
        text-align: start;
        margin: 0 0 1.5rem;
    }

    .about_section_6 > .main > .item > .content > .descriptions{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .about_section_6 > .main > .item > .content > .descriptions > p{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: black;
        font-size: 0.75rem;
        text-align: start;
        margin: 0 0 0.7rem;
    }

    .about_section_6 > .controls{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .about_section_6 > .controls > .line{
        width: auto;
        height: 0;
        border-top: 1px solid black;
        flex: 1;
    }

    .about_section_6 > .controls > button{
        width: 2rem;
        height: 2rem;
        background-color: white;
        border: none;
        outline: none;
        margin-left: 1rem;
        cursor: pointer;
    }

    .about_section_6 > .controls > button:disabled{
        cursor: default;
    }
    
    .about_section_6 > .controls > button > svg{
        width: 100%;
        height: 100%;
        transition: opacity 0.2s ease;
    }

    .about_section_6 > .controls > button:nth-child(2) > svg{
        transform: rotate(180deg);
    }

    .about_section_6 > .controls > button:disabled > svg{
        opacity: 0.3;
    }
}