.MuiAccordionSummary-content{
    font-family: 'OpenSans-Regular';
    font-weight: normal;
    color: white;
    font-size: 1rem;
    text-align: start;
    letter-spacing: 0.02rem;
}

.MuiAccordionDetails-root{
    font-family: 'OpenSans-Regular';
    font-weight: normal;
    color: white;
    opacity: 0.8;
    font-size: 0.75rem;
    text-align: start;
    letter-spacing: 0.02rem;
}

.MuiAccordionSummary-root .vertical{
    transition: transform 150ms ease;
    transform-origin: 50% 50%;
    transform: scaleY(1);
}

.MuiAccordionSummary-root .Mui-expanded .vertical{
    transform: scaleY(0);
    transform-origin: 50% 50%;
}

@media (max-width: 480px)  {

    .MuiAccordionSummary-content{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        font-size: 1rem;
        text-align: start;
        letter-spacing: 0.02rem;
    }


    .MuiAccordionDetails-root{
        font-family: 'OpenSans-Regular';
        font-weight: normal;
        color: white;
        opacity: 0.8;
        font-size: 0.85rem;
        text-align: start;
        letter-spacing: 0.02rem;
    }
}