.nav{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 3rem 2rem; */
    box-sizing: border-box;
}

.nav_section_1{
    width: 100%;
    height: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.nav_section_1 > img{
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: center;
}

.nav_section_2{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.nav_section_2 > a{
    width: 100%;
    font-family: 'OpenSans-Regular';
    font-weight: normal;
    color: white;
    font-size: 1.5rem;
    text-align: start;
    letter-spacing: 0.02rem;
    margin: 0 0 1rem;
    text-decoration: none;
}

.nav_section_3{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.nav_section_3 > a{
    width: 100%;
    font-family: 'OpenSans-Regular';
    font-weight: normal;
    color: #FFFFFF80;
    font-size: 1rem;
    text-align: start;
    letter-spacing: 0.02rem;
    margin: 0 0 0.5rem;
    text-decoration: none;
}
